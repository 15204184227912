import '../globals.css';

import React from 'react';
import { type AppProps } from 'next/app';
import { datadogRum } from '@datadog/browser-rum';
import NextAdapterPages from 'next-query-params/pages';
import { QueryParamProvider } from 'use-query-params';

import { beforeSendRumEvent } from '~/lib/datadogRum';

datadogRum.init({
  applicationId: process.env['NEXT_PUBLIC_DATADOG_APPLICATION_ID'] as string,
  clientToken: process.env['NEXT_PUBLIC_DATADOG_CLIENT_TOKEN'] as string,
  site: 'datadoghq.com',
  service: 'portal',
  env: process.env.NODE_ENV,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  beforeSend: beforeSendRumEvent,
});

function PortalApp({ Component, pageProps }: AppProps) {
  return (
    <QueryParamProvider adapter={NextAdapterPages}>
      <Component {...pageProps} />
    </QueryParamProvider>
  );
}

export default PortalApp;
