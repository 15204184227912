import { RumEvent } from '@datadog/browser-rum';

export function beforeSendRumEvent(event: RumEvent) {
  const secretKeyRegex = /sk_(test|live)_[-_a-zA-Z0-9]+/g;
  if (event.type === 'action' && event.action.target?.name.match(secretKeyRegex)) {
    event.action.target.name = event.action.target.name.replace(secretKeyRegex, (match) =>
      match.startsWith('sk_test') ? 'sk_test_***' : 'sk_live_***'
    );
  }
  return true;
}
